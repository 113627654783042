
import './App.css';
import React, {useEffect } from "react";
import Marquee from "react-fast-marquee";
import expertise from './expertise.json';
import officelocation from './officelocation.json';
import contact from './contact.json';
import { Button } from '@react-email/button';
import MetaTags from 'react-meta-tags';
import {  scrollToTop } from "./App";
import Project from "./project.js"

function ThankYou() {
 
  useEffect(() => {
    const allWithClass = Array.from(
      document.getElementsByClassName('kCEPRZ')
    );
    console.log(allWithClass);
    scrollToTop();
 
  }, [])
   
  



  return (
    <div className="App Contact-Us-main">
     <MetaTags>
            
            <meta name="description" content="Contact us for Web, Mobile & AR/VR App Development Requirements. Our Team will get back to you with further details. Talk to Us!" />
            <meta name="keyword" content="Mobile App Development, Hire Laravel Developer, Hire Codeigniter Developer, Hire Javascript Developer, hire web developer, ui ux web developer, Web Engineering, Digital Marketing, Game Developing, Web CMS And Portal Development" />
            <meta property="og:title" content="Thank You - Web, AR/VR & Mobile App Development Company" />
            <link rel="canonical" href="https://arccusinc.com/thankyou" />
            <title>Thank You | Arccus Inc</title>
      </MetaTags>
     
      <section className='Contact-us  banner-sec'>
        <div className='container'>
            {/* <h1 className='text-center thankyou-txt'>Thank  <span className='lastword'>you!</span> </h1> */}
        </div>
      </section>

      <section className='contact-us-form'>
        <div className='container'>
           <div className="row">
                <div className="col-lg-4 contact-us-left">
                    <h2>Contact details</h2>
                    <p>Delaying can hinder your progress; we are just a call away.</p>
                    <ul>
                    {contact.map( contact => {
                      return(
                      <li>
                        <ul>
                
                        </ul>
                        <ul>
                          <li><h3>Business Email</h3></li>
                          <li className="contact-detail">
                          <Button href={contact.mail}>
                          {contact.mailid}
                          </Button>                       
                          </li>
                        </ul>
                        <ul>
                          <li><h3>Office Hours</h3></li>
                          <li className="contact-detail">{contact.day}</li>
                          <li className="contact-detail">{contact.time}</li>
                        </ul>
                      </li>
                      )})} 
                    </ul>
                </div>
                <div className="col-lg-8 contact-us-right  d-flex flex-column align-items-center justify-content-center">
                    {/* <h2 className="pb-0">Thank you</h2> */}
                    <p className="mb-0">Thank you for your enquiry</p>
                    <p className="mb-0">We will contact you very soon.</p>
                </div>
           </div>
        </div>
      </section>

      <section className='project'>
           <div className='container position-relative'>
              <div className='carosoul-control'>
                        <h2>View Our Featured  Client  <span className='lastword'>Projects</span></h2>
                </div>
                 
                <div className='position-class'>
                  <div className='project-carosoul '>
                    <Project />
                  </div>  
                </div> 
                {/* <p className='text-center mt-5'>
                  <a href='https://works.arccusinc.com' target='blank' className='quote ml-auto'>Show More</a>
                </p> */}
            </div>
      </section>

      <section className='get-in-touch'>
          <div className='container'>
               <h4>Get in Touch</h4>
               <h2>Our Office Locations</h2>
               <div className="row location-row">
               {officelocation.map( officelocation => {
                      return(
                    <div className="location col-lg-4 col-sm-12 col-md-6">
                        <img src={officelocation.image}  alt={"location"} loading='lazy' />
                        <h5>{officelocation.location}</h5>
                        <p>{officelocation.address}</p>
                    </div>
                    )})} 
               </div>
          </div>
      </section>

    

      <section className='expertise'>
        <div className='container'>
           <div className="row align-items-center">
                <div className="col-md-2 col-sm-3">
                    <h4>EXPERTISE</h4>
                </div>
                <div className="technology col-md-10 col-sm-9">
                <Marquee gradient={false} pauseOnHover={true}>
                {expertise.map( expertise => {
                      return(
                  <div className=''>
                      <img src={expertise.image} alt={"expertise"} loading='lazy' />
                  </div>
                  )})} 
              </Marquee>
                </div>
           </div>
        </div>
      </section>

     

    </div>
  );
}

export default ThankYou;
