import "./App.css";
import "./responsive.css";
import Header from "./header.js";
import Footer from "./footer.js";
import "react-tabs/style/react-tabs.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./home";
import WebappDevelopment from "./web-app-development";
import AboutUs from "./about-us";
import ContactUs from "./contact-us";
import Blog from "./blog";
import Career from "./career";
import PrivacyPolicy from "./privacypolicy";
import Faq from "./faq";
import Apply from "./apply.js";
import BlogDetail from "./BlogDetail.js";
import MobileappDevelopment from "./mobile-app-development.js";
import LocomotiveScroll from "locomotive-scroll";
import { useEffect } from "react";
import ARVRDevelopment from "./arvrdevelopment";
import Privacypolicy from "./privacy-policy";
import Termsandcondition from "./terms-condition";
import ThankYou from "./thankyou";
import About from "./about.js";

export const scrollToTop = () => {
  scroll != null && scroll.scrollTo(0, 0, 1000);
};
export const scrollToTop1 = () => {
  scroll != null && scroll.scrollTo(255, 0, 1000);
};

export let scroll = null;
function App() {
  useEffect(() => {
    const handlePageRefresh = (event) => {
      // Check if the refresh button or F5 key was pressed
      if (event.ctrlKey || event.metaKey || event.key === "F5") {
        event.preventDefault();
        window.location.href = "/"; // Redirect to the home page
      }
    };

    window.addEventListener("beforeunload", handlePageRefresh);

    return () => {
      window.removeEventListener("beforeunload", handlePageRefresh);
    };
  }, []);

  useEffect(() => {
    scroll = new LocomotiveScroll({
      el: document.querySelector("#main-container"),
      smooth: true,
      multiplier: 0.9,
      pinning: true,
    });

    new ResizeObserver(() => scroll.update()).observe(
      document.querySelector("[data-scroll-container]")
    );

    scroll.on("scroll", (position) => {
      if (position.scroll.y > 50) {
        document.querySelector(".navbar").classList.add("fixed-header");
      } else {
        document.querySelector(".navbar").classList.remove("fixed-header");
      }
    });
    scroll.on("scroll", (position) => {
      if (position.scroll.y > 300) {
        document.querySelector(".back-to-top").classList.add("show");
      } else {
        document.querySelector(".back-to-top").classList.remove("show");
      }
    });
    function handleScrollToTop() {
      scroll.scrollTo(0, 0, 1000);
    }
    const button = document.querySelector(".back-to-top");
    button.addEventListener("click", handleScrollToTop);

    // cleanup function to destroy scroll instance
    return () => scroll.destroy();
    button.removeEventListener("click", handleScrollToTop);
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <div className="Apps" id="main-container" data-scroll-container>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="web-development" element={<WebappDevelopment />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="blog" element={<Blog />} />
            <Route path="career" element={<Career />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="faq" element={<Faq />} />
            <Route path="apply" element={<Apply />} />
            <Route path="/:name" element={<BlogDetail />} />
            <Route
              path="mobile-app-development"
              element={<MobileappDevelopment />}
            />
            <Route path="ar-vr-development" element={<ARVRDevelopment />} />
            <Route path="privacypolicy" element={<Privacypolicy />} />
            <Route path="termsandcondition" element={<Termsandcondition />} />
            <Route path="test-page" element={<About />} />
            <Route path="thankyou" element={<ThankYou />} />
          </Routes>

          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
